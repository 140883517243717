import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import { SharedItemTile } from '../../shared/SharedItemTile/SharedItemTile';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';
import { useSbSharedItemTracking } from '../hooks/sharedItemTracking/useSbSharedItemTracking';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

import { type SbSharedRecentlySoldItems_itemSearch$key } from './__generated__/SbSharedRecentlySoldItems_itemSearch.graphql';
import { type SbSharedRecentlySoldItems_viewer$key } from './__generated__/SbSharedRecentlySoldItems_viewer.graphql';

import styles from './SbSharedRecentlySoldItems.scss';
import dibsCss from 'dibs-css';
type Props = {
    viewer: SbSharedRecentlySoldItems_viewer$key;
    itemSearch: SbSharedRecentlySoldItems_itemSearch$key;
};

export const SbSharedRecentlySoldItems: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
}) => {
    const viewer = useFragment(
        graphql`
            fragment SbSharedRecentlySoldItems_viewer on Viewer {
                ...SharedItemTile_viewer
                ...useSbSharedItemTracking_viewer
            }
        `,
        viewerRef
    );

    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRecentlySoldItems_itemSearch on ItemSearchQueryConnection {
                ...SharedItemTile_itemSearch
                ...useSbSharedItemTracking_itemSearch
                soldResults {
                    edges {
                        node {
                            item {
                                serviceId
                                ...useSbSharedItemTracking_item
                                ...SharedItemTile_item
                                    @arguments(
                                        isTrade: $isTrade
                                        priceBookName: $priceBookName
                                        showSeller: $showSeller
                                    )
                            }
                        }
                    }
                }
            }
        `,
        itemSearchRef
    );

    const { isMobile } = useServerVarsContext();

    const thinBrowseSoldItems = (itemSearch?.soldResults?.edges || [])
        .map(item => item?.node?.item || null)
        .filter(filterFalsy);

    const { fireItemImpressionTracking, fireItemClickTracking } = useSbSharedItemTracking({
        viewer,
        itemSearch,
        items: thinBrowseSoldItems,
        pageType: 'previously available',
    });

    if (!thinBrowseSoldItems.length) {
        return null;
    }

    return (
        <>
            <BarHeader
                title={
                    <FormattedMessage
                        id="sb.SbSharedRecentlySoldItems.title"
                        defaultMessage="Previously Available Items"
                    />
                }
            />
            <QuickViewProvider>
                <div
                    className={classnames(dibsCss.flex, dibsCss.flexWrap, {
                        [styles.container]: isMobile,
                    })}
                    data-tn="previously-available-items"
                >
                    {thinBrowseSoldItems.map((soldItem, index) => {
                        return (
                            <SharedItemTile
                                user={null}
                                key={soldItem?.serviceId}
                                index={index}
                                item={soldItem}
                                showSellerName={false}
                                viewer={viewer}
                                itemSearch={itemSearch}
                                onContentVisible={() =>
                                    fireItemImpressionTracking({
                                        itemId: soldItem?.serviceId,
                                        index,
                                    })
                                }
                                onClick={() =>
                                    fireItemClickTracking({
                                        itemId: soldItem?.serviceId,
                                        index,
                                    })
                                }
                                hideRecentSoldPrice
                            />
                        );
                    })}
                </div>
            </QuickViewProvider>
        </>
    );
};
