import { graphql } from 'react-relay/legacy';
import get from 'lodash/get';

import {
    QP_PERSONALIZED_FILTER,
    QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE,
    QP_PERSONALIZED_FILTER_VALUE_WHITE_GLOVE_SHIPPING,
    QP_CURRENCY,
} from '../../constants/queryParamConstants';

const personalizedFiltersActions = [
    QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE,
    QP_PERSONALIZED_FILTER_VALUE_WHITE_GLOVE_SHIPPING,
];

function personalizedFilterActionExists(filterValue) {
    return personalizedFiltersActions.includes(filterValue);
}

export function getPersonalizedFilterValues() /*: string[] */ {
    if (typeof window === 'undefined') {
        return [];
    }
    const searchParams = new URLSearchParams(window.location.search);
    const personalizedfilter = searchParams.get(QP_PERSONALIZED_FILTER);

    if (typeof personalizedfilter === 'string') {
        return personalizedfilter.split(',');
    }

    return [];
}

export function hasPersonalizedFilterQueryParam() {
    const personalizedFilter = getPersonalizedFilterValues();
    return personalizedFilter.some(_pf => personalizedFilterActionExists(_pf));
}

export function getUrlWithoutPersonalizedFilterParam() {
    if (typeof window === 'undefined') {
        return '';
    }
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(QP_PERSONALIZED_FILTER);
    return `${window.location.pathname}?${searchParams.toString()}`;
}

export function getPersonalizedFilterAction(filterValue) {
    return personalizedFilterActionExists(filterValue) ? filterValue : '';
}

export function getPersonalizedFilterUriRef({ filterName, filterValues, uriRef, currency }) {
    const searchParams = new URLSearchParams(uriRef);

    if (searchParams.has(filterName)) {
        searchParams.delete(filterName);
    }

    if (filterValues && filterValues.length) {
        const urlLabels = filterValues.map(value => value.urlLabel);
        let additionalSearchParams = urlLabels.sort().join(',');
        if (
            filterName === QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE &&
            uriRef.indexOf('/jewelry/') !== -1
        ) {
            additionalSearchParams = `${additionalSearchParams},rest-of-world`;
        }
        searchParams.append(filterName, additionalSearchParams);
    }

    if (currency && !searchParams.has(QP_CURRENCY)) {
        searchParams.append(QP_CURRENCY, currency.toLowerCase());
    }

    return decodeURIComponent(searchParams.toString());
}

export const shippingFiltersFragment = graphql`
    fragment personalizedFiltersHelpers_shippingFilters on ShippingFilterType @relay(plural: true) {
        totalCount
    }
`;

export const shouldApplyPersonalizedFilter = ({ personalizedFilterValue, shippingFilters }) => {
    if (personalizedFilterValue === QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE) {
        return !!get(shippingFilters, '[0].totalCount');
    }
    // Default is true as if there are no rules for some specific personalized filter it should just apply it.
    return true;
};
