/**
 * @generated SignedSource<<0da8a08796ef1a9dfcfd427e6354fd8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRecentlySoldItems_itemSearch$data = {
  readonly soldResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly item: {
          readonly serviceId: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_item" | "useSbSharedItemTracking_item">;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_itemSearch" | "useSbSharedItemTracking_itemSearch">;
  readonly " $fragmentType": "SbSharedRecentlySoldItems_itemSearch";
};
export type SbSharedRecentlySoldItems_itemSearch$key = {
  readonly " $data"?: SbSharedRecentlySoldItems_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRecentlySoldItems_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRecentlySoldItems_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "soldResultsConnection",
      "kind": "LinkedField",
      "name": "soldResults",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "soldResultsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemSearchResult",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Item",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "serviceId",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "useSbSharedItemTracking_item"
                    },
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "isTrade",
                          "variableName": "isTrade"
                        },
                        {
                          "kind": "Variable",
                          "name": "priceBookName",
                          "variableName": "priceBookName"
                        },
                        {
                          "kind": "Variable",
                          "name": "showSeller",
                          "variableName": "showSeller"
                        }
                      ],
                      "kind": "FragmentSpread",
                      "name": "SharedItemTile_item"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "09019e617853532c865875eba314f4d1";

export default node;
