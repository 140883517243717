import { Component } from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

export class SharedNotificationsWrapperComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnabled: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { notifications, type } = nextProps;

        this.setState({ isEnabled: !!notifications[type] });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.isEnabled !== nextState.isEnabled;
    }

    render() {
        const { isEnabled } = this.state;
        const { notifications = {}, type } = this.props;
        const { [type]: notification } = notifications;

        return notification ? this.props.children({ isEnabled }) : null;
    }
}

SharedNotificationsWrapperComponent.propTypes = {
    notifications: PropTypes.object,
    type: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    const notifications = get(state, 'sharedNotificationsReducer.notifications') || {};
    return { notifications };
};

export const SharedNotificationsWrapper = connect(mapStateToProps)(
    SharedNotificationsWrapperComponent
);
