/**
 * @generated SignedSource<<c2e3709ebba677043687ed8a64735f2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSearchResultContainer_item$data = ReadonlyArray<{
  readonly isSold: boolean | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item" | "SharedItemTile_item" | "useItemShippingPrices_item" | "useSbSharedItemTracking_item" | "useSharedUrgencySignals_item">;
  readonly " $fragmentType": "SbRespSearchResultContainer_item";
}>;
export type SbRespSearchResultContainer_item$key = ReadonlyArray<{
  readonly " $data"?: SbRespSearchResultContainer_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSearchResultContainer_item">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchTileVideo"
    },
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbRespSearchResultContainer_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSold",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FavoritesProvider_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSharedUrgencySignals_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useItemShippingPrices_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_item"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "fetchVideo",
          "variableName": "fetchTileVideo"
        },
        {
          "kind": "Variable",
          "name": "isTrade",
          "variableName": "isTrade"
        },
        {
          "kind": "Variable",
          "name": "priceBookName",
          "variableName": "priceBookName"
        },
        {
          "kind": "Variable",
          "name": "showSeller",
          "variableName": "showSeller"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SharedItemTile_item"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "b38479dcf43d17d13af1309d71fa81dd";

export default node;
