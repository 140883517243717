import { type FC } from 'react';
import { graphql, useFragment } from 'react-relay';

import { trackSearchWithinResults } from '../../utils/tracking/searchBrowse/filterTracking';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';
import { SbSharedRefineMenuFilters } from '../SbSharedRefineMenu/SbSharedRefineMenuFilters/SbSharedRefineMenuFilters';
import { SbSharedRefineMenuDisplayPrefsSelect } from '../SbSharedRefineMenu/SbSharedRefineMenuDisplayPrefsSelect/SbSharedRefineMenuDisplayPrefsSelect';
import { SbSharedRefineMenuSearchWithin } from '../SbSharedRefineMenu/SbSharedRefineMenuSearchWithin/SbSharedRefineMenuSearchWithin';
import { useSbRespRefineMenuFiltersMap } from './SbRespRefineMenuFilters/sbRespRefineMenuFiltersMap';

import { PRICE } from '../SbSharedRefineMenu/sbSharedRefineMenuConstants';
import { CATEGORY_LEVEL_1 } from '../../utils/categoryHelpers';
import { ATTRIBUTE_ITEMTYPE, ATTRIBUTE_JEWELRYTYPE } from '../../constants/attributesConstants';

import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { uriIncludesVertical } from '../../utils/uriUtils';

import { type SbRespRefineMenu_viewer$key } from './__generated__/SbRespRefineMenu_viewer.graphql';
import { type SbRespRefineMenu_itemSearch$key } from './__generated__/SbRespRefineMenu_itemSearch.graphql';
import { type SbRespRefineMenu_filters$key } from './__generated__/SbRespRefineMenu_filters.graphql';
import { type SbRespRefineMenu_user$key } from './__generated__/SbRespRefineMenu_user.graphql';

const viewerFragment = graphql`
    fragment SbRespRefineMenu_viewer on Viewer {
        ...SbSharedRefineMenuFilters_viewer
    }
`;

const itemSearchFragment = graphql`
    fragment SbRespRefineMenu_itemSearch on ItemSearchQueryConnection {
        displayUriRef
        pageType
        ...SbSharedRefineMenuFilters_itemSearch
        ...SbSharedRefineMenuDisplayPrefsSelect_itemSearch
        ...SbSharedRefineMenuSearchWithin_itemSearch
    }
`;
const filtersFragment = graphql`
    fragment SbRespRefineMenu_filters on SearchBrowseFilter @relay(plural: true) {
        ...SbSharedRefineMenuFilters_filters
        ...SbSharedRefineMenuDisplayPrefsSelect_filters
    }
`;

const userFragment = graphql`
    fragment SbRespRefineMenu_user on User {
        ...SbSharedRefineMenuFilters_user
        ...SbSharedRefineMenuDisplayPrefsSelect_user
    }
`;

type Props = {
    viewer: SbRespRefineMenu_viewer$key;
    itemSearch: SbRespRefineMenu_itemSearch$key;
    filters: SbRespRefineMenu_filters$key;
    user: SbRespRefineMenu_user$key;
    isClient: boolean;
    currency: string;
    uriRef: string;
};

export const SbRespRefineMenu: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    filters: filtersRef,
    user: userRef,
    isClient,
    currency,
    uriRef,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const filters = useFragment(filtersFragment, filtersRef);
    const user = useFragment(userFragment, userRef);

    const { displayUriRef, pageType } = itemSearch;
    const { isReorderedFilterMenuVariant } = useServerVarsContext();

    const initiallyExpandedFilters =
        isReorderedFilterMenuVariant && uriIncludesVertical(displayUriRef || '')
            ? [CATEGORY_LEVEL_1, PRICE]
            : [CATEGORY_LEVEL_1, PRICE, ATTRIBUTE_ITEMTYPE, ATTRIBUTE_JEWELRYTYPE];

    const sbRespRefineMenuFiltersMap = useSbRespRefineMenuFiltersMap();

    return (
        <>
            <SbSharedRefineMenuSearchWithin
                horizontalSpacing="small"
                onSubmit={trackSearchWithinResults}
                itemSearch={itemSearch}
            />
            <SbSharedRefineMenuFilters
                user={user}
                uriRef={uriRef}
                viewer={viewer}
                filters={filters}
                currency={currency}
                isClient={isClient}
                itemSearch={itemSearch}
                filterMap={sbRespRefineMenuFiltersMap}
                initiallyExpanded={filterName => initiallyExpandedFilters.includes(filterName)}
            />
            {pageType !== pageTypes.DESIGN && (
                <SbSharedRefineMenuDisplayPrefsSelect
                    user={user}
                    filters={filters}
                    itemSearch={itemSearch}
                    isClient={isClient}
                />
            )}
        </>
    );
};
